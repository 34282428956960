import React from "react";
import "../education.css";
import PortableText from "@sanity/block-content-to-react";
import {serializers} from "../../../api/sanity";

function CardEducation(props) {
  const {data} = props;
  return (
    <div>
      <h2 className="topic">{data.name}</h2>
      <div className="flip-box">
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img
              className="img-style"
              src={data.mainImage}
              alt="member photo"
            />
          </div>
          <div className="flip-box-back">
            <img
              className="img-style"
              src={data.mainImage}
              alt="member photo"
            />
            <div className="pStyle">
              <PortableText blocks={data.content} serializers={serializers}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardEducation;
