import React from "react";
import "./singlepost.css";
import ReactPlayer from "react-player";
import PortableText from "@sanity/block-content-to-react";
import Gallery from "../gallery/gallery";
import {serializers} from "../../api/sanity";

function SinglepostSection(props) {
  const { film } = props;
  return (
    <div id="singlepost" className={"fixed-section"}>
      <main className="single-post-content">
        <div className="content-container">
          <div className="top-row">
            <h1 className="post-title">{film.title}</h1>
            <div className="horizontal-line-x">
              <div className="line"></div>
              <div className="close-btn-animated">
                <button
                  onClick={props.onClose}
                  className="x-btn x-btn-animated"
                >
                  close
                </button>
              </div>
            </div>
          </div>
          <div className="bottom-row">
            {film.mainImage ? (
              <img src={film.mainImage} className="post-featured-image" />
            ) : (
              <img
                src={require("../NoImage.jpeg")}
                className="post-featured-image"
              />
            )}
            <div className="col middle-col">
              <PortableText blocks={film.description} serializers={serializers}/>
              {film.production && <div className="line-title-block">
                <span className="block-label">
                  <strong>Production</strong>
                </span>
                <div className="block-line-horizontal"></div>
              </div>}
              {film.production && <PortableText blocks={film.production} serializers={serializers} />}
              {film.mainCrew && <div className="line-title-block">
                <span className="block-label">
                  <strong>Main Crew</strong>
                </span>
                <div className="block-line-horizontal"></div>
              </div>}
              {film.mainCrew && <PortableText blocks={film.mainCrew} serializers={serializers}/>}
              {film.awards &&
                <div className="line-title-block">
                  <span className="block-label">
                    <strong>Awards</strong>
                  </span>
                  <div className="block-line-horizontal"></div>
                </div>}
              {film.awards && <PortableText blocks={film.awards} serializers={serializers}/>}
              {film.support &&
                <div className="line-title-block">
                  <span className="block-label">
                    <strong>Support</strong>
                  </span>
                  <div className="block-line-horizontal"></div>
                </div>
              }
              <div className="support-logos">
                {film.support &&
                  film.support.map((item, index) => (
                    <a href={item.url} target={"_blank"}>
                      <img src={film.supportUrls[index]} />
                    </a>
                  ))}
              </div>
            </div>
            <div className="col new-row">
              <div className="widget">
                {film.trailerUrl &&
                  <div className="line-title-block">
                    <span className="block-label">
                      <strong>Trailer</strong>
                    </span>
                    <div className="block-line-horizontal"></div>
                  </div>}
                <ReactPlayer
                  className="trailer-video"
                  controls={true}
                  url={film.trailerUrl}
                />
              </div>
              <div className="widget">
                {film.stillsUrls &&
                  <div className="line-title-block mt20">
                    <span className="block-label">
                      <strong>Stills</strong>
                    </span>
                    <div className="block-line-horizontal"></div>
                  </div>}
                <div className="stills-gallery-grid">
                  <Gallery imageUrls={film.stillsUrls} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SinglepostSection;
