import React from "react";
import "../filmography.css";

function CardFilmography(props) {
    const {film} = props;
  return (
      <div className="film-card">
        <img
          src={film.thumbnail || 'assets/no_image_thumb.jpg'}
        />
        <div className="film-body">
          <div className="col-left">
            <h4 className="film-title">{film.title}</h4>
            <ul className="film-meta">
              <li>
                <strong>Director</strong>: {film.director}
              </li>
            </ul>
          </div>
          <div className="col-right">
            <ul className="status-data">
              <li>{film.status}</li>
              <li>{film.releaseDate}</li>
            </ul>
          </div>
        </div>
        <div className="arrow-link-element">
          <div className="arrow-body"></div>
          <div className="arrow-point"></div>
        </div>
      </div>
  );
}

export default CardFilmography;
