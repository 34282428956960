import React, { useEffect, useState } from "react";
import "./partners.css";
import {getPartnersSectionInfo, serializers} from "../../api/sanity";
import PortableText from "@sanity/block-content-to-react";

function PartnersSection() {
  const [leftInfo, setLeftInfo] = useState(null);
  const [rightInfo, setRightInfo] = useState(null);

  useEffect(() => {
    function callAsyncFunction() {
      getPartnersSectionInfo("left").then((res) => setLeftInfo(res));
      getPartnersSectionInfo("right").then((res) => setRightInfo(res));
    }

    callAsyncFunction();
  }, []);
  return (
    <div id="partners" className="section" data-anchor="partners">
      <strong className="section-title">PARTNERS</strong>
      <img
        src="assets/exports/partners/partners_page_birds.png"
        alt="placeholder birds"
        className="birds-animated"
      />
      <img
        src="assets/exports/partners/partners_page_tree.png"
        alt="placeholder tree"
        className="tree-animated"
      />
        <a href="#home">
            <img
                src="assets/exports/filmography/filmography_page_small_logo.png"
                alt="small logo"
                className="small-logo-animated"
            />
        </a>

      <div className="content-container">
        <div className="col">
          {leftInfo && (
            <>
              <h3>{leftInfo.partnersTitle}</h3>
              <div className="columns-wrapper">
                {leftInfo.partners.map((item, index) => (
                  <div key={index} className="column-holderTEMP">
                    <PortableText blocks={item.partner} serializers={serializers}/>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="col">
          {rightInfo && (
            <>
              <h3>{rightInfo.partnersTitle}</h3>
              <div className="columns-wrapper">
                {rightInfo.partners.map((item, index) => (
                  <div key={index} className="column-holderTEMP">
                    <PortableText blocks={item.partner} serializers={serializers}/>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PartnersSection;
