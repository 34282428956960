import React from "react";
import "./news2.css";
import PortableText from "@sanity/block-content-to-react";
import {serializers} from "../../api/sanity";

function News2Section(props) {
  const {post} = props;
  return (
    <div id="news2" className={'fixed-section'}>
      <main className="single-post-content">
        <div className="content-container">
          <strong className="section-title">news</strong>
          <div className="top-row">
            <h1 className="post-title">{post.title}</h1>
            <div className="horizontal-line-x">
              <div className="line"></div>
              <div className="close-btn-animated">
                <button onClick={props.onClose} className="x-btn x-btn-animated">
                  close
                </button>
              </div>
            </div>
          </div>
          <div className="bottom-row">
            <div className="col">
              { post.mainImage ? <img
                src={post.mainImage}
                alt="post image"
                className="post-featured-image"
              /> :
              <img
              src={require("../NoImage.jpeg")}
              className="post-featured-image"
            />
            }
            </div>
            <div className="col middle-col">
              <PortableText blocks={post.description} serializers={serializers}/>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default News2Section;
