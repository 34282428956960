import React from "react";
import './form.css'
import{ send, init } from '@emailjs/browser';
init("WqeKPPfDxMkAauT7u");

const ContactForm = (props) => {
    const name = React.createRef()
    const lastName = React.createRef()
    const email = React.createRef()
    const phone = React.createRef()
    const address = React.createRef()
    const message = React.createRef()
    const checkbox = React.createRef()
    function sendFormData(e) {
        e.preventDefault()
        const data = {
            name: name.current.value,
            lastName: lastName.current.value,
            email: email.current.value,
            phone: phone.current.value,
            address: address.current.value,
            subscribe: checkbox.current.checked ,
            message: message.current.value
        }
        send('service_8289y8n', 'template_n2iskyf', {
            fromName: data.name + ' ' + data.lastName,
            reply_to: data.email,
            message: `Form data from Petra Website: Name: ${data.name}\n Last name: ${data.lastName}\n email: ${data.email}\n
            phone: ${data.phone} \n Address: ${data.address} \n Subscribe: ${data.subscribe}\n Message: ${data.message}`
        }).then(r => console.log('response', r));
    }

    return (
        <div className={'form-wrapper'}>
            <form className="my-form" onSubmit={sendFormData}>
                <div className="container">
                    <h1>Subscribe to out newsletter</h1>
                    <ul>
                        <li>
                            <div className="grid grid-2">
                                <input type="text" placeholder="Name" required ref={name}/>
                                <input type="text" placeholder="Last name" required ref={lastName}/>
                            </div>
                        </li>
                        <li>
                            <div className="grid grid-1">
                                <input type="email" placeholder="Email" required ref={email}/>
                                <input type="tel" placeholder="Phone" ref={phone}/>
                                <input type="text" placeholder="Address" ref={address}/>
                                <textarea placeholder="Message" ref={message}/>
                            </div>
                        </li>
                        <li>
                            <input type="checkbox" id="terms" ref={checkbox}/>
                            <label htmlFor="terms">Would you like to receive occasional emails from Petra</label>
                        </li>
                        <li>
                            <div className="grid grid-3">
                                <div className="required-msg">REQUIRED FIELDS</div>
                                <button className="btn-grid" type="submit">
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt=""/>
            </span>
                                    <span className="front">SIGN UP</span>
                                </button>
                                <button className="btn-grid" onClick={props.onClose}>
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg" alt=""/>
            </span>
                                    <span className="front">CLOSE</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;