const sanityClient = require('@sanity/client')
const authToken = 'skx8eXYuVgG2KxeheA0vlwW2BF3nL7xX6VNr4owziJkY7Son8fy5wDLmlXRWgi5fcf1FIOIkjfxhG5QUskODcMv30lcgXyYkm7zTU2zAXSYP0GNC8QuE5T7M0IYAFKnCacdA1ZiQjzFKCZs2vY7HIBk4g27Wu4nZIp1wIFwUH0wtq8snN5Pu'
const client = sanityClient({
    projectId: '43bz5282',
    dataset: 'production',
    apiVersion: '2021-03-25',
    token: authToken,
});

export async function getAllMovies() {
    const query = '*[_type == "movie" && !(_id in path("drafts.**"))]{..., "mainImage": mainImage.asset->url, ' +
        '"supportUrls": support[].image.asset->url, "thumbnail": thumbnail.asset->url, "stillsUrls": gallery[].asset->url}|order(order asc)'
    const result = await client.fetch(query);
    return result
}

export async function getMovieBySlug(slug) {
    const query = `*[_type == "movie" && (slug.current == "${slug}")]{..., "mainImage": mainImage.asset->url}`
    const result = await client.fetch(query);
    if (result.length) {
        return result[0];
    }
    return {};
}

export async function getAllTeamMembers() {
    const query = '*[_type == "teamSection" && !(_id in path("drafts.**"))]{..., "teamMembersUrls": teamMembers[].image.asset->url}|order(_updatedAt desc)[0]'
    const result = await client.fetch(query);
    return result
}

export async function getAllNews() {
    const query = '*[_type == "news" && !(_id in path("drafts.**"))]{..., "mainImage": mainImage.asset->url}|order(order asc)'
    const result = await client.fetch(query);
    return result
}

export async function getAllEducationPosts() {
    const query = '*[_type == "education" && !(_id in path("drafts.**"))]{..., "mainImage": image.asset->url, "imageUrls": gallery[].asset->url}|order(order asc)[0..3]'
    const result = await client.fetch(query);
    return result
}

export async function getHomeSectionInfo() {
    const query = '*[_type == "homeSection" && !(_id in path("drafts.**"))]{..., "image": image.asset->url}|order(_updatedAt desc)[0]'
    const result = await client.fetch(query);
    return result
}

export async function getContactSectionInfo() {
    const query = '*[_type == "contactSection" && !(_id in path("drafts.**"))]|order(_updatedAt desc)[0]'
    const result = await client.fetch(query);
    return result
}

export async function getPartnersSectionInfo(position) {
    const query = `*[_type == "partnersSection" && !(_id in path("drafts.**")) && (position == "${position}")]|order(_updatedAt desc)[0]`
    const result = await client.fetch(query);
    return result
}

export const serializers = {
    marks: {
        link: ({ children, mark }) =>
                <a href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
    }
}