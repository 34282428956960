import React, {createRef} from "react";
import "./education2.css";
import PortableText from "@sanity/block-content-to-react";
import ImageGallery from "react-image-gallery";
import Gallery from "../gallery/gallery";
import {serializers} from "../../api/sanity";

function Education2Section(props) {
  const {data} = props;
  const {imageUrls} = data;

  return (
    <div id="education2" className={'fixed-section'}>
      <main className="single-post-content">
        <div className="content-container">
          <strong className="section-title">education</strong>
          <div className="top-row">
            <h1 className="post-title">{data.name}</h1>
            <div className="horizontal-line-x">
              <div className="line"></div>
              <div className="close-btn-animated">
                <button onClick={props.onClose} className="x-btn x-btn-animated">
                  close
                </button>
              </div>
            </div>
          </div>
          <div className="bottom-row">
            <div className="col">
              <img
                src={data.mainImage}
                alt="post image"
                className="post-featured-image"
              />
            </div>
            <div className="col middle-col">
              <div className="post-text">
                <PortableText blocks={data.insideContent} serializers={serializers}/>
              </div>
            </div>

                        <div className="col">
                            <Gallery imageUrls={imageUrls} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Education2Section;
