import React, { useEffect, useState } from "react";
import CardEducation from "./card-education/card-education-component";
import "./education.css";
import { getAllEducationPosts } from "../../api/sanity";
import Education2Section from "../education2/education2-component";
import HomeSection from "../home/home-component";

function EducationSection() {
  const [educations, setEducations] = useState([]);
  const [popup, setPopup] = useState(null);
  useEffect(() => {
    function callAsyncFunction() {
      getAllEducationPosts().then((res) => setEducations(res));
      //spinner exit
    }
    callAsyncFunction();
  }, []);
  function closePopup() {
    setPopup(null);
  }
  return (
    <>
      {popup && <Education2Section data={popup} onClose={() => closePopup()} />}
      <div
        id="education"
        className="section pp-scrollable"
        data-anchor="education"
      >
        <img
          className="home-section-birds-animated animate__animated animate__fadeInTopRight"
          src="assets/exports/homepage/home_page_birds.png"
          alt="birds image animated"
        />
        <strong className="section-title">EDUCATION</strong>
        <img
          src="assets/exports/education/education_page_two_trees.png"
          alt="two small trees animated graphic"
          className="small-trees-animated"
        />
        <img
          src="assets/exports/education/education_page_tree.png"
          alt="tree animated graphic"
          className="tree-animated"
        />
        <a href="#home">
          <img
            src="assets/exports/filmography/filmography_page_small_logo.png"
            alt="logo animated graphic"
            className="small-logo-animated"
          />
        </a>
        <div className="content-container">
          <div className="members-grid-wrapper">
            {educations &&
              educations.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setPopup(item)}
                  style={{ cursor: "pointer" }}
                >
                  <CardEducation data={item} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default EducationSection;
