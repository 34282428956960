import React, { useEffect, useState } from "react";
import CardTeam from "./card-team/card-team-component";
import "./team.css";
import {getAllTeamMembers, serializers} from "../../api/sanity";
import PortableText from "@sanity/block-content-to-react";

function TeamSection() {
  const [teamInfo, setTeamInfo] = useState(null);
  useEffect(() => {
    function callAsyncFunction() {
      getAllTeamMembers().then((res) => setTeamInfo(res));
      //spinner exit
    }
    callAsyncFunction();
  }, []);
  return (
    <div id="team" className="section pp-scrollable" data-anchor="team">
      <strong className="section-title">TEAM</strong>
      <img
        src="assets/exports/team/team_page_tree.png"
        alt="tree animated graphic"
        className="tree-animated"
      />
      <a href="#home">
        <img
          src="assets/exports/filmography/filmography_page_small_logo.png"
          alt="logo animated graphic"
          className="small-logo-animated"
        />
      </a>

      <div className="content-container">
        <div className="members-grid-wrapper">
          {teamInfo &&
            teamInfo.teamMembers.map((item, index) => (
              <CardTeam
                key={index}
                imageUrl={teamInfo.teamMembersUrls[index]}
                member={item}
              />
            ))}
        </div>

        <div className="team-members">
          {teamInfo &&
            teamInfo.separatedTeams.map((item, index) => (
              <div key={index} className="team-column">
                <PortableText blocks={item.team} serializers={serializers}/>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TeamSection;
