import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React, {useEffect, useState} from "react";
import CardNews from "../news/card-news/card-news-component";
import CardFilmography from "../filmography/card-filmography/card-filmography-component";
import "./slider.css";
import News2Component from "../news2/news2-component";
import SinglepostSection from "../singlepost/singlepost-component";
import { componentWillAppendToBody } from "react-append-to-body";


function Slider(props) {
    const [itemsPerSlide, setItemsPerSlide] = useState(6);
    const isNews = props.isNews;
    const items = props.items;

    const [post, setPost] = useState(null);
    const [film, setFilm] = useState(null);

    const AppendedMyComponent = componentWillAppendToBody(News2Component);
    const AppendedMyComponent2 = componentWillAppendToBody(SinglepostSection);

    useEffect(() => {
        const width = window.innerWidth;
        if (width <= 1024 && width >= 768) {
            setItemsPerSlide(4);
        } else if (width < 768) {
            setItemsPerSlide(2)
        }
    }, []);

    useEffect(() => {
        (post || film) ? document.documentElement.style.overflow = "hidden" :
            document.documentElement.style.overflow = "unset";
    },[post, film]);


    function closePopup() {
        if(isNews){
            setPost(null)
        } else {
            setFilm(null)
        }
    }

    function handleClick(item) {
        if(isNews){
            setPost(item)
        } else {
            setFilm(item);
        }
    }

    return (
        <div className={'slider-section'}>
            {post && <AppendedMyComponent post={post} onClose={() => closePopup()}/>}
            {film && <AppendedMyComponent2 film={film} onClose={() => closePopup()}/>}
            <div className="slider-wrapper">
                <div className={isNews ? 'swiper-news swiper' : 'swiper-film swiper'}>
                    <div className="swiper-wrapper">
                        {items && [...Array(Math.ceil(items.length / itemsPerSlide))].map((item, index) =>
                            <div className="swiper-slide" key={index}>
                                {items.slice(index * itemsPerSlide, (index + 1) * itemsPerSlide).map((item, index2) =>
                                    <div key={index2} onClick={() => handleClick(item)}>
                                        {isNews ? <CardNews post={item}/> : <CardFilmography film={item}/>}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="swiper-navigation">
                    <div className={isNews ? 'left-arrow-navigation left-arrow-navigation-news' :
                        'left-arrow-navigation left-arrow-navigation-film'}></div>
                    <div className={isNews ? 'right-arrow-navigation right-arrow-navigation-news' :
                        'right-arrow-navigation right-arrow-navigation-film'}></div>
                </div>
            </div>
        </div>
    );
}

export default Slider;
