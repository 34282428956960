import React from "react";
import "../news.css";
import moment from "moment";

function CardNews(props) {
    const {post} = props;
  return (
    <div className="post-card">
      <img
        src={post.mainImage || 'assets/no_image_thumb.jpg'}
        alt="placeholder image"
      />
      <small className="post-date">{moment(post._createdAt).format('DD.MM.YYYY')}</small>
      <h4 className="post-title">
        {post.title}
      </h4>
      <div className="arrow-link-element">
        <div className="arrow-body"></div>
        <div className="arrow-point"></div>
      </div>
    </div>
  );
}

export default CardNews;
