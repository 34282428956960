import './App.css';
import HomeSection from './components/home/home-component';
import NewsSection from './components/news/news-component';
import FilmographySection from './components/filmography/filmography-component';
import PartnersSection from './components/partners/partners-component';
import TeamSection from './components/team/team-component';
import EducationSection from './components/education/education-component';
import ContactsSection from './components/contacts/contacts-component';
import {useEffect} from "react";

import $ from "jquery";
import './libraries/pagepiling/jquery.pagepiling'
import './libraries/pagepiling/jquery.pagepiling.css'
import Swiper, {Navigation} from "swiper";
import "swiper/css";
import 'swiper/css/navigation';

function App() {
    useEffect(() => {
        const navbar = document.getElementById("navbar");
        const isMobile = window.innerWidth < 768
        const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024
        if (!isMobile) {
            $(document).ready(function () {
                $('#section-container').pagepiling({
                    menu: null,
                    navigation: null,
                    onLeave: function (index, nextIndex, direction) {
                        if (index === 2 && direction === 'up' && !isMobile && !isTablet) {
                            $('#home').append(navbar);
                            navbar.classList.remove("sticky");
                        }
                    },
                    afterLoad: function (anchorLink, index) {
                        if (index !== 1 && !isMobile && !isTablet) {
                            navbar.classList.add("sticky");
                            $(document.body).append(navbar);
                        }
                        if (anchorLink){
                            window.history.pushState(anchorLink, 'Petra Pan Film', `/#${anchorLink}`);
                        } else {
                            window.history.pushState('home', 'Petra Pan Film', `/`);
                        }
                    }
                });
            });
        }
        Swiper.use([Navigation]);
        const swiper = new Swiper(".swiper-news", {
            direction: "horizontal",
            pagination: {
                el: ".swiper-pagination",
            },

            navigation: {
                nextEl: ".right-arrow-navigation-news",
                prevEl: ".left-arrow-navigation-news",
                disabledClass: "swiper-button-disabled"
            },
        });

        const swiper2 = new Swiper(".swiper-film", {
            direction: "horizontal",

            pagination: {
                el: ".swiper-pagination",
            },

            navigation: {
                nextEl: ".right-arrow-navigation-film",
                prevEl: ".left-arrow-navigation-film",
                disabledClass: "swiper-button-disabled"
            },
        });
    }, [])
    return (
        <div className="App" id={"section-container"}>
            <HomeSection/>
            <NewsSection/>
            <FilmographySection/>
            <PartnersSection/>
            <TeamSection/>
            <EducationSection/>
            <ContactsSection/>
        </div>
    );
}

export default App;
