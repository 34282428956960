import React, {useEffect, useState} from "react";
import "./home.css";
import Menu from "../menu/menu-component";
import {elastic as Menu2} from 'react-burger-menu'
import {getHomeSectionInfo, serializers} from "../../api/sanity";
import PortableText from "@sanity/block-content-to-react";



function HomeSection() {
    const [isMobile, setIsMobile] = useState(false)
    const [homeInfo, setHomeInfo] = useState({})
    const [areMenuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        function callAsyncFunction() {
            getHomeSectionInfo().then(res => setHomeInfo(res));
            //spinner exit
        }
        callAsyncFunction();
        const _isMobile = window.innerWidth < 1024;
        setIsMobile(_isMobile);
    }, [])
    return (
        <>
            {isMobile &&
                <Menu2
                    right
                    isOpen={areMenuOpen}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    customBurgerIcon={<img src="assets/exports/menu/menu-icon.svg"/>}
                    customCrossIcon={<img src="assets/exports/menu/cross-icon.svg"/>}>
                    <ul>
                        <li className="nav-item">
                            <a href="#news" onClick={() => setMenuOpen(prev => !prev)}>News</a>
                        </li>
                        <li className="nav-item">
                            <a href="#filmography" onClick={() => setMenuOpen(prev => !prev)}>Filmography</a>
                        </li>
                        <li className="nav-item">
                            <a href="#partners"  onClick={() => setMenuOpen(prev => !prev)}>Partners</a>
                        </li>
                        <li className="nav-item">
                            <a href="#team" onClick={() => setMenuOpen(prev => !prev)}>Team</a>
                        </li>
                        <li className="nav-item">
                            <a href="#education" onClick={() => setMenuOpen(prev => !prev)}>Education</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" onClick={() => setMenuOpen(prev => !prev)}>Contacts</a>
                        </li>
                    </ul>
                </Menu2>}
            <div id="home" className="section">
                <Menu/>
                <img
                    className="tree"
                    src="assets/exports/homepage/home_page_tree.png"
                    alt="birds image animated"
                />
                <img
                    className="home-section-birds-animated animate__animated animate__fadeInTopRight"
                    src="assets/exports/homepage/home_page_birds.png"
                    alt="birds image animated"
                />
              <div className="content-wrapper">
                {homeInfo && <>
                  <img
                      className="logo logo--black animate__animated animate__flipInY"
                      src={homeInfo.image}
                      alt="Petra pan film logo"
                  />
                    {homeInfo.title && <PortableText blocks={homeInfo.title} serializers={serializers}/>}
                    {homeInfo.description && <PortableText blocks={homeInfo.description} serializers={serializers}/>}
                </>}
              </div>
                <img
                    src="assets/exports/homepage/home_page_big_bird.png"
                    alt="big bird graphic animated"
                    className="bird-graphic-animated animate__animated animate__slideInRight"
                />
            </div>
        </>
    );
}

export default HomeSection;
