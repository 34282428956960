import ImageGallery from "react-image-gallery";
import React, { createRef, useState } from "react";
import galleryStyle from "./gallery.css";

const Gallery = (props) => {
  const [images, setImages] = useState([]);
  let { imageUrls } = props;
  const galleryRef = createRef();

  if (imageUrls && images.length === 0) {
    const images = imageUrls.map((url) => ({
      original: url,
      thumbnail: url,
    }));
    setImages(images);
  }
  const onThumbnailClick = () => {
    galleryRef.current.fullScreen();
  };
  return (
    <ImageGallery
      ref={galleryRef}
      useBrowserFullscreen={false}
      onThumbnailClick={onThumbnailClick}
      items={images}
    />
  );
};
export default Gallery;
