import React from "react";
import "../team.css";
import PortableText from "@sanity/block-content-to-react";
import {serializers} from "../../../api/sanity";

function CardTeam(props) {
    const {member, imageUrl} = props;
    return (
        <div className="single-member">
            <img
                src={imageUrl}
                alt="member photo"
            />
            <div className="member-data">
                <h3 className="member-name">{member.name}</h3>
                <h4 className="member-role">{member.roles}</h4>
                <p className="member-bio">
                    {member.birthInfo}
                    <br/>
                </p>
                <div style={{marginTop: '-0.5rem'}} className="member-bio">
                    <PortableText blocks={member.bio} serializers={serializers}/>
                </div>
            </div>
        </div>
    );
}

export default CardTeam;
