import React, { useEffect, useState } from "react";
import Slider from "../slider/slider";
import "./filmography.css";
import { getAllMovies } from "../../api/sanity";
import HomeSection from "../home/home-component";

function FilmographySection() {
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    function callAsyncFunction() {
      getAllMovies().then((res) => setMovies(res));
    }
    callAsyncFunction();
  }, []);
  return (
    <div id="filmography" className="section" data-anchor="filmography">
      <strong className="section-title">FILMOGRAPHY</strong>
      <img
        src="assets/exports/filmography/filmography_page_tree.png"
        alt=""
        className="tree-animated"
      />
        <a href="#home">
        <img
          src="assets/exports/filmography/filmography_page_small_logo.png"
          alt=""
          className="small-logo-animated"
        />
        </a>
        <Slider isNews={false} items={movies} />
    </div>
  );
}

export default FilmographySection;
