import React, { useEffect, useState } from "react";
import "./contats.css";
import ContactForm from "../form/form";
import { componentWillAppendToBody } from "react-append-to-body";
import {getContactSectionInfo, serializers} from "../../api/sanity";
import PortableText from "@sanity/block-content-to-react";

const AppendedMyComponent = componentWillAppendToBody(ContactForm);

function ContactsSection() {
  const [form, setForm] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const onClose = () => {
    setForm(false);
  };
  useEffect(() => {
    function callAsyncFunction() {
      getContactSectionInfo().then((res) => setContactInfo(res));
      //spinner exit
    }
    callAsyncFunction();
  }, []);

  useEffect(() => {
    form ? document.documentElement.style.overflow = "hidden" : document.documentElement.style.overflow = "unset";
  },[form]);

  return (
    <>
      {form && <AppendedMyComponent onClose={onClose} />}
      <div id="contact" className="section" data-anchor="contact">
        <img
          src="assets/exports/contacts/contacts_page_tree.png"
          alt="tree animated graphic"
          className="tree-animated"
        />
        <a href="/#home">
        <img
          src="assets/exports/filmography/filmography_page_small_logo.png"
          alt="logo animated graphic"
          className="small-logo-animated"
        />
        </a>

        <img
          src="assets/exports/contacts/contacts_page_tree_with_house.png"
          alt="tree with birdhouse animated graphic"
          className="tree-birdhouse-animated"
        />

        <main className="content-container">
          {contactInfo && (
            <>
              <div className="top-row">
                <div className="col-left">
                  <PortableText blocks={contactInfo.leftSide} serializers={serializers}/>
                </div>
                <div className="col-right">
                  <PortableText blocks={contactInfo.rightSide} serializers={serializers}/>
                </div>
              </div>
              <div className="bottom-row">
                <PortableText blocks={contactInfo.middleSide} serializers={serializers}/>
                <button className={"btn-contact"} onClick={() => setForm(true)}>
                  CONTACT US
                </button>
              </div>
            </>
          )}
        </main>
      </div>
    </>
  );
}

export default ContactsSection;
