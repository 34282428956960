import React, {useEffect, useState} from "react";
import "./menu.css";
import {elastic as Menu} from 'react-burger-menu'

function MainMenu() {
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        const _isMobile = window.innerWidth <= 1024;
        setIsMobile(_isMobile);
    }, [])

    return (
        !isMobile ?
                <nav id="navbar" className="main-menu">
                    <ul>
                        <li className="nav-item"><a href="#news">News</a></li>
                        <li className="nav-item">
                            <a href="#filmography">Filmography</a>
                        </li>
                        <li className="nav-item">
                            <a href="#partners">Partners</a>
                        </li>
                        <li className="nav-item"><a href="#team">Team</a></li>
                        <li className="nav-item">
                            <a href="#education">Education</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact">Contacts</a>
                        </li>
                    </ul>
                </nav> : <></>
    )

}

export default MainMenu;
