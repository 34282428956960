import React, {useEffect, useState} from "react";
import "./news.css";
import Slider from "../slider/slider";
import {getAllNews} from "../../api/sanity";

function NewsSection() {
    const [news, setNews] = useState([]);
    useEffect(() => {
        function callAsyncFunction() {
            getAllNews().then(res => setNews(res));
            //spinner exit
        }
        callAsyncFunction();
    },[]);
  return (
    <div id="news" className="section" data-anchor="news">
      <strong className="section-title">NEWS</strong>
      <img
        src="assets/exports/newspage/news_page_birds.png"
        alt=""
        className="birds-animated"
      />
      <img
        src="assets/exports/newspage/news_page_tree_with_house.png"
        alt=""
        className="tree-animated"
      />
      <Slider isNews = {true} items={news}/>
    </div>
  );
}

export default NewsSection;
